import React from "react";

const MoesifBranding = (
  <div className="project__body moesif-branding">
    Illustration
    <p>
      I am also in charge of creating all the marketing assets and
      illustrations. At first I re-composed the characters in a pre-bough
      illustration pack. however one issue i notices was they were all white.
      one of the main focus for my work is to include illustrations with more
      diversity. I first started working with prepurchased pack of illustrations
      which was great.
    </p>
  </div>
);

export default MoesifBranding;
